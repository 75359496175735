
import Vue from "vue";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";
// @ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";
// @ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
// @ts-ignore
import { isNull, isUndefined, isEmpty, debounce } from "lodash";
// @ts-ignore
import TermListSegment from "./tab_contents/termListSegment.vue";
// @ts-ignore
import TermUser from "./tab_contents/termUser.vue";
// @ts-ignore
import SegSearch from "./tab_contents/SegmentSearch.vue";
// @ts-ignore
import { SegmentSearchOption } from "@/interfaces/segment";
// @ts-ignore
import { mapActions } from "vuex";

const MINIMUM_LENGTH_CHARACTER = 3;

export default Vue.extend({
  name: "User",

  props: {
    line: {
      type: Object,
      default: function () {
        return {};
      },
    },
    user: {
      type: Object,
      default: function () {
        return {};
      },
    },
    predicates: {
      type: Object,
      default: function () {
        return {};
      },
    },
    is_edit: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    CardTextField,
    CardAutocomplete,
    CardSwitch,
    TermListSegment,
    IconBase,
    TermUser,
    SegSearch,
  },

  data: () => ({
    panel: [],
    modeFilterSegment: 1,
    resources: {
      segments_providers: [],
      data_segment_provider: [],
      segment_search_list: [],
    },
    segmentSearch: {
      search: "",
      options: {
        page: 1,
        limit: 20,
        data_provider_key: undefined,
      } as SegmentSearchOption,
    },
    modes: {
      load: false,
      search: false,
      disabledCbx: true,
      disabledTxt: false,
    },
  }),

  async created() {
    this.$nextTick(async () => {
      await this.fetchSegmentsProviders();
    });
  },

  async mounted() {},

  computed: {
    getMsgSearch() {
      return this.$t("targeting.user.search");
    },

    getOptionSearchSegment() {
      return [
        {
          id: 1,
          value: "All",
        },
      ];
    },

    isLoad() {
      return this.modes.load;
    },

    isLoadingAndNotSearch() {
      return !this.modes.load && !this.modes.search;
    },

    isLoadingAndSearch() {
      return !this.modes.load && this.modes.search;
    },
  },

  methods: {
    ...mapActions("segment", ["getSegmentsProviders", "getSegmentSearch"]),

    ...mapActions("proccess", ["setLoadingField"]),

    async fetchSegmentsProviders() {
      this.modes.load = true;
      await this.getSegmentsProviders()
        .then(response => {
          this.resources.segments_providers = response;
          this.modes.load = false;
        })
        .catch(() => {
          this.modes.load = false;
        });
      let ownerSegments = {
        id: 0,
        name: "modifier.labels.user.your_segments.label",
        key: "yourSegments",
      };
      this.resources.segments_providers.push(ownerSegments);
      this.resources.segments_providers.sort((a, b) => {
        let fa = a.id,
          fb = b.id;
        if (fa < fb) return -1;
        if (fa > fb) return 1;
        return 0;
      });
      this.resources.segments_providers.forEach(m => {
        let key = m.key;
        let obj = {
          keySegment: key,
          total: 0,
          totalSelected: 0,
          showTotal: false,
        };
        this.resources.data_segment_provider.push(obj);
      });
    },

    async removeHandler(event: any) {
      this.$emit(event.unique ? "remove-item-unique" : "remove-item", event.params);
    },

    async addItem(event: any) {
      this.$emit(event.unique ? "add-item-unique" : "add-item", event.params);
    },

    async getSegmentSearchList() {
      this.resources.segment_search_list = [];
      this.resources.segment_search_list = await this.getSegmentSearch(this.segmentSearch);
      this.modes.disabledTxt = false;
      this.SelectedAllItemSearch();
    },

    getShowTotalSegmentProviders(key: String) {
      let result = this.resources.data_segment_provider.find(({ keySegment }) => keySegment === key)?.showTotal;
      return isUndefined(result) ? false : Boolean(result);
    },

    getTotalSegmentProviders(key: String) {
      let result = this.resources.data_segment_provider.find(({ keySegment }) => keySegment === key)?.total;
      return isUndefined(result) ? "-1" : String(result);
    },

    getTotalSelectedSegmentProviders(key: String) {
      let result = this.resources.data_segment_provider.find(({ keySegment }) => keySegment === key)?.totalSelected;
      return isUndefined(result) ? "-1" : String(result);
    },

    UpdateDataCount(params: any) {
      this.resources.data_segment_provider.find(({ keySegment }) => keySegment === params.keySegment).total =
        params.count;
      this.resources.data_segment_provider.find(({ keySegment }) => keySegment === params.keySegment).showTotal = true;
    },

    UpdateDataSelected(params: any) {
      this.resources.data_segment_provider.find(({ keySegment }) => keySegment === params.keySegment).totalSelected =
        params.count;
    },

    setAttributes(modeSearch, modeDisabled) {
      this.modes.search = modeSearch;
      this.modes.disabledTxt = modeDisabled;
    },

    SelectedAllItemSearch() {
      this.panel = [...Array(this.resources.segment_search_list).keys()].map((k, i) => i);
    },

    checkSearch(val: string | null) {
      return !isEmpty(val) && !isNull(val) && val.length < MINIMUM_LENGTH_CHARACTER;
    },

    updateTermUser() {
      const components = this.$refs.CTermUser;
      if (!isUndefined(components)) {
        components.forEach(comp => {
          comp.updateAllSegments();
        });
      }
    },
  },
  watch: {
    "segmentSearch.search": debounce(async function (val: string | null) {
      if (isNull(val) || isEmpty(val) || isUndefined(val)) {
        this.setAttributes(false, false);
        this.updateTermUser();
        return;
      }
      if (this.checkSearch(val)) return;
      this.setAttributes(true, true);
      await this.setLoadingField(true);
      await this.getSegmentSearchList();
      await this.setLoadingField(false);
    }, 500),
  },
});
